.balls {
  width: 5em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.balls div {
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  margin-right: 4px;
  animation: wave 0.8s ease-in-out alternate infinite;
}

.balls div:nth-of-type(1) {
  animation-delay: -1.0s;
}

.balls div:nth-of-type(2) {
  animation-delay: -0.8s;
}
.balls div:nth-of-type(3) {
  animation-delay: -0.6s;
}
.balls div:nth-of-type(4) {
  animation-delay: -0.4s;
}
.balls div:nth-of-type(5) {
  animation-delay: -0.2s;
}
@keyframes ocultar {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.box {
  perspective: 120px;
}

.plane {
  width: 2em;
  height: 2em;
  background-color: #fc2f70;
  transform: rotate(0);
  animation: flip 1s infinite;
}

@keyframes flip {
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(180deg) rotateX(180deg);
  }
}


.bar {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 2em;
}

.bar span {
  width: 0.3em;
  height: 1em;
  /* background-color: #3cefff; */
}

.bar span:nth-of-type(1) {
  animation: grow 1s -0.45s ease-in-out infinite;
}

.bar span:nth-of-type(2) {
  animation: grow 1s -0.3s ease-in-out infinite;
}

.bar span:nth-of-type(3) {
  animation: grow 1s -0.15s ease-in-out infinite;
}

.bar span:nth-of-type(4) {
  animation: grow 1s ease-in-out infinite;
}

@keyframes grow {
  0%,
  100% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(2);
  }
}

.spin {
  border: 3px solid hsla(185, 100%, 62%, 0.2);
  /* border-top-color: #3cefff; */
  border-radius: 50%;
  width: 3em;
  height: 3em;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  width: 2.5em;
  height: 3em;
  border: 3px solid transparent;
  border-top-color: #fc2f70;
  border-bottom-color: #fc2f70;
  border-radius: 50%;
  animation: spin-stretch 2s ease infinite;
}

@keyframes spin-stretch {
  50% {
    transform: rotate(360deg) scale(0.4, 0.33);
    border-width: 8px;
  }
  100% {
    transform: rotate(720deg) scale(1, 1);
    border-width: 3px;
  }
}

.loader {
  position: relative;
}

.outer,
.middle,
.inner {
  border: 3px solid transparent;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
}

.outer {
  width: 3.5em;
  height: 3.5em;
  margin-left: -1.75em;
  margin-top: -1.75em;
  animation: spin 2s linear infinite;
}

.middle {
  width: 2.1em;
  height: 2.1em;
  margin-left: -1.05em;
  margin-top: -1.05em;
  animation: spin 1.75s linear reverse infinite;
}

.inner {
  width: 0.8em;
  height: 0.8em;
  margin-left: -0.4em;
  margin-top: -0.4em;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.square {
  position: relative;
  width: 2em;
  height: 2em;
  border: 3px solid #da810c;
  overflow: hidden;
  animation: rot 3s ease infinite;
}

.square::before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  width: 2em;
  height: 2em;
  background-color: hsla(98, 100%, 62%, 0.597);
  transform-origin: center bottom;
  transform: scaleY(1);
  animation: fill 3s linear infinite;
}

@keyframes rot {
  50%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fill {
  25%,
  50% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

.dots {
  width: 5em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.dots div {
  margin-left: 5px;
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  background-color: #fc2f70;
  animation: ocultar 0.8s ease-in-out alternate infinite;
}

.dots div:nth-of-type(1) {
  animation-delay: -0.8s;
}

.dots div:nth-of-type(2) {
  animation-delay: -0.6s;
}
.dots div:nth-of-type(3) {
  animation-delay: -0.45s;
}

.dots div:nth-of-type(4) {
  animation-delay: -0.35s;
}
.dots div:nth-of-type(5) {
  animation-delay: -0.25s;
}

@keyframes wave {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(100%);
  }
}
