@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;700&display=swap');
body{
  font-family: 'Poppins', sans-serif;
  margin: 0;
}
input:focus{
  outline: #aaaaaa !important;
}
.card{

/* margin: 4px 4px 4px 4px ; */
}
.next{
  background-image: url('../src/assets/images/equipo.jpg');
  background-size: cover;
}
.nextUser{
  background-image: url('../src/assets/images/equipo.jpg');
  background-size: cover;
}
.nextUser::after{
  content:"";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 45%;
  background-color: #750016;
  z-index: -1;
}

.boton:hover{
  transform: scale(1.1);
  transition: all .2s ease-in-out;
}
.boton{
  transition: all .2s ease-in-out;
}
.acordion{
  transition: all .2s ease-in-out;
}

.react-horizontal-scrolling-menu--item {
  background-color: #222328;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 20px;
  max-width: 25%;
    min-width: 24%;
    height: 90%;
    border-radius: 25px;
}
.react-horizontal-scrolling-menu--scroll-container {
  height: 98% !important;
}
.react-horizontal-scrolling-menu--wrapper  {
  height: 100%;
}
h1{
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    
}
h2{
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}
#description{
  font-family: 'Poppins', sans-serif;
  font-weight: regular;
}

#menu-toggle:checked + #menu{
    display: block;
}
#menu::before,#menu::after{
    transition: all 1s ease-out;
}
.navicon {
    width: 1.125em;
    height: .125em;
  }

  .navicon::before,
  .navicon::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all .2s ease-out;
    content: '';
    background: #ffff;
  }

  .navicon::before {
    top: 5px;
  }

  .navicon::after {
    top: -5px;
  }

  .menu-btn:not(:checked) ~ .menu {
    display: none;
  }

  .menu-btn:checked ~ .menu {
    display: block;
  }

  .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
  }

  .menu-btn:checked ~ .menu-icon .navicon::before {
    transform: rotate(-45deg);
  }

  .menu-btn:checked ~ .menu-icon .navicon::after {
    transform: rotate(45deg);
  }

  .menu-btn:checked ~ .menu-icon .navicon::before,
  .menu-btn:checked ~ .menu-icon .navicon::after {
    top: 0;
  }

  .content-q1uestion {
    margin-top: 40%;
  }

  /*media queries celular*/
  @media screen and (max-width: 768px) {
    .content-q1uestion {
      margin-top: 110%;
    }
  }
  .background-banner{
    background: rgb(57,57,57);
    background: radial-gradient(circle, rgba(57,57,57,1) 35%, rgba(19,19,19,1) 100%);
  }
